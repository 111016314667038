import React from 'react';
import Aboutus from './Aboutus';
import Hero from './Hero';
import Features from './Features';
import Funfact from './Funfact';
import Chooseus from './Chooseus';
import Contact from './Contact';
import Testimonials from './Testimonials';
import Projects from './Projects';
import Contactus from './Contactus';
import Newsletter from './Newsletter';
import OurService from './OurService';
import Blogpage from './Blogpage';
import Slider from './Slider/Slider';

const Blog = () => {
  return (
    <div>
      {/* <Hero/> */}
      <Slider/>
      <Features/>
       <Aboutus/> 
       {/* <Funfact/> */}
       <OurService/>
       <Blogpage/>
       {/* <Brand/> */}
       <Chooseus/>
        <Contact/><br></br>
       {/* <Testimonials/>  */}
       {/* <Projects/> */}
       {/* <Contactus/> */}
      
       <Newsletter/>
    </div>
  );
}

export default Blog;
