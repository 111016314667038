import React from 'react';

const Physicaldesign = () => {
  return (
    <div>
{/* <!-- start page-title --> */}
<section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Physical Design & STA</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Physical Design & STA</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img src="assets/images/service/STA.jpg" alt="" />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="">Physical Design & STA</a>
                                    </h2>
                                    <p>ROUTE2SOC offers comprehensive physical design services for block and SoC levels, ensuring a seamless journey from netlist to GDS. With expertise spanning technology nodes from 28nm to 4nm, we deliver exceptional results.</p>
                                    <p>Our physical design services encompass critical stages such as floor planning, placement, clock tree synthesis, routing and optimization, timing closure, and signoff checks including PV, LEC, and STA. We take pride in our unparalleled expertise in IO timing, floor planning, PG planning, place and route, clock tree synthesis, and post-route optimization.</p>
                                    <p>At ROUTE2SOC, we specialize in implementing cutting-edge low-power techniques, such as clock gating, power gating, multi-Vt, and voltage islands, optimizing power efficiency. Leveraging industry-leading tools like Synopsys ICC 2 compiler, PrimeTime, Design Compiler, and Cadence Innovus, Tempus, Genus, we ensure the highest level of precision and efficiency in our physical design services.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/design-for-testability"><span><i class="icon-arrow"></i>Design for Testability </span> </a></li>
                                    <li><a href="/ASIC-RTL-design"><span><i class="icon-arrow"></i>ASIC/ RTL Design</span></a></li>
                                    <li><a href="/PhysicalDesign-STA"><span><i class="icon-arrow"></i>Physical Design & STA </span> </a></li>
                                    <li><a href="/FPGA-design"><span><i class="icon-arrow"></i>FPGA Design </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default Physicaldesign;
