import React from "react";

const Contact = () => {
  return (
    <div>
      <section class="cta-area">
        <div class="container">
          <div
            class="cta-wrap ptb-120"
            style={{
              background: "url(assets/images/cta.jpg) no-repeat center top",
              backgroundSize: "cover",
            }}
          >
            {" "}
            <div class="cta-inner">
              <div class="ch-top-title" style={{width:"100%"}}>
                <h2>
                Reach us for Technology Solutions{" "}
                </h2>
              </div>
              <div class="cotact-wrap">
                <a href="tel:0254582478" class="call-btn">
                  <i class="icon-envolpe"></i>
                  <p>
                    Get Contact Now <strong>info@route2soc.com</strong>
                  </p>
                </a>
                <a href="/contacts" class="ch-btn-style-4">
                  Connect With Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
