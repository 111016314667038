import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 testimonials at a time
    slidesToScroll: 1
  };
  

  return (
    <div>
      <section className="testimonial-section ptb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="ch-top-title-s3">
              <span>OUR TESTIMONIAL</span>
              <h2>Check Energy Customer Say</h2>
            </div>
            <div className="testimonial-items">
              <Slider {...settings}>
                <div className="testimonial-item">
                  <div className="testimonial-item-inner">
                  <ul className="ratting">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                    </ul>
                    <div className="testimonial-text">
                      <p>
                        He development of reliable scalable software solutiany,
                        browser and device. We bring together deepin expertise
                        and the latest advan.
                      </p>
                      <div className="info-wrap">
                        <div className="info-item">
                          <div className="info-text">
                            <h4>Barata Maras</h4>
                            <span>/ Founder of Solar</span>
                          </div>
                          <div className="info-img">
                            <img
                              src="assets/images/testimonial/img-1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="quote">
                        <i className="icon-quote" style={{fontSize:"60px", color:"#ECECEC"}}></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="testimonial-item-inner">
                  <ul className="ratting">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                    </ul>
                    <div className="testimonial-text">
                      <p>
                        He development of reliable scalable software solutiany,
                        browser and device. We bring together deepin expertise
                        and the latest advan.
                      </p>
                      <div className="info-wrap">
                        <div className="info-item">
                          <div className="info-text">
                            <h4>Latar Barata</h4>
                            <span>/ CEO & Founder</span>
                          </div>
                          <div className="info-img">
                            <img
                              src="assets/images/testimonial/img-2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="quote">
                        <i className="icon-quote"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-item">
                  <div className="testimonial-item-inner">
                  <ul className="ratting">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star"></i>
                        </span>
                      </li>
                    </ul>
                    <div className="testimonial-text">
                      <p>
                        He development of reliable scalable software solutiany,
                        browser and device. We bring together deepin expertise
                        and the latest advan.
                      </p>
                      <div className="info-wrap">
                        <div className="info-item">
                          <div className="info-text">
                            <h4>Tom Montu</h4>
                            <span>/ Admin of Solar</span>
                          </div>
                          <div className="info-img">
                            <img
                              src="assets/images/testimonial/img-3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="quote">
                        <i className="icon-quote"></i>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add more testimonial items similarly */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
