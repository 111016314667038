import React from "react";

const Services = () => {
  return (
    <div>
     {/* <!-- start page-title --> */}
     <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Our Services</h2>
                  <ol>
                    <li>
                      <a href="index.html">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Services</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      <section className="service-area-2 ptb-120" style={{ padding: "120px 0" }}>
        <div className="container">
          <div className="service-wrap">
            <div className="ch-top-title-s3">
              <span>OUR BEST SERVICE </span>
              <h2>Energy For Society With Service</h2>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="service-item" style={{ animationDuration: "1000ms" }}>
                  <div className="service-item-wrap">
                    <div className="service-text">
                      <div className="icon">
                        <i className="icon-panel"></i>
                      </div>
                      <h3>
                        <a href="service-single.html">Connecting You to the Future</a>
                      </h3>
                      <p>
                        Write a short info that will describe the title useful is something useful
                      </p>
                    </div>
                    <div className="service-img middle-shade">
                    <img src="assets/images/service/service-5.jpg" alt="hi" />
                    </div>
                  </div>
                  <div className="service-btm">
                    <ul>
                      <li>
                        <i className="ti-location-pin"></i>London, UK
                      </li>
                      <li>
                        <i className="ti-check-box"></i>$4,000 Monthly
                      </li>
                    </ul>
                    <a href="service-single.html">
                      Read More <i className="icon-arrow-dobble"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                {/* Repeat the above structure for other service items */}
                <div className="service-item" style={{ animationDuration: "1200ms" }}>
                  <div className="service-item-wrap">
                    <div className="service-text">
                      <div className="icon">
                        <i className="icon-bolb"></i>
                      </div>
                      <h3>
                        <a href="service-single.html">Energy Solutions</a>
                      </h3>
                      <p>
                        Write a short info that will describe the title useful is something useful
                      </p>
                    </div>
                    <div className="service-img middle-shade">
                      <img src="assets/images/service/service-5.jpg" alt="hi" />
                    </div>
                  </div>
                  <div className="service-btm">
                    <ul>
                      <li>
                        <i className="ti-location-pin"></i>London, UK
                      </li>
                      <li>
                        <i className="ti-check-box"></i>$4,000 Monthly
                      </li>
                    </ul>
                    <a href="service-single.html">
                      Read More <i className="icon-arrow-dobble"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* Repeat the above structure for other service items */}
            </div>
          </div>
        </div>
      </section>
      <div className="service-single-page ptb-120">
            <div className="container">
                <div className="service-single-top">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="service-single-img">
                                <img src="assets/images/service-single/single.jpg" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="service-single-category">
                                <h3>Service List:</h3>
                                <ul>
                                    <li><a href="#">Solar facilisis Service</a></li>
                                    <li><a href="#">Solar Meta-Services</a></li>
                                    <li><a href="#">Medical Of Best Service</a></li>
                                    <li><a href="#">Facilisis Commercial Service</a></li>
                                    <li><a href="#">Best facilisis Service</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="service-details">
                        <h2>Medical Service Details:</h2>
                        <p>It is a long established fact that a reader will be distracted restore inexpensive e-markets vis to vis corporate intellectual capital. Holisticly reinvent compelling niche markets via scalable strategic. by the readable content of a page when looking at its layout. The point to thisof usingis that normal distribution of Medical</p>
                        <p>We is a long established fact that a reader will be distracted restore inexpensive e-markets vis to vis corporate intellectual capital. Holisticly reinvent compelling niche markets via scalable strategic. by the readable content of a page when looking.</p>
                        <div className="choose-wrap">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="choose-items">
                                        <div className="info">
                                            <div className="info-icon">
                                                <i className="icon-enargey2"></i>
                                            </div>
                                            <div className="info-text">
                                                <h3>Creative Idea Buildup</h3>
                                                <p>Are you provide a revolutionary level of transparency solar this campaigns - from backlink acquisition.</p>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="info-icon">
                                                <i className="icon-pie"></i>
                                            </div>
                                            <div className="info-text">
                                                <h3>Reliable & Energy Solar</h3>
                                                <p>Are you provide a revolutionary level of transparency solar this campaigns - from backlink acquisition.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="service-single-img">
                                        <img src="assets/images/service-single/single2.jpg" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="mb-0">It is a long established fact that a reader will be distracted restore inexpensive e-markets vis to vis corporate intellectual capital. Holisticly reinvent compelling niche markets via scalable strategic. by the readable content of a page when looking at its layout. The point to thisof usingis that normal distribution of Medical</p>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
  );
};

export default Services;
