import React from "react";
// import "./About.css"; // Import the CSS file for styles

const About = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>About Us</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>About Us</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      <section className="about-area style-2 ptb-120">
        <div className="container">
          <div className="about-wrap">
            <div className="row">
              <div className="col col-lg-6 col-md-12 col-12 about-left-item">
                <div className="about-image">
                  <div className="left-to-right-shade">
                    <img src="assets/images/about/about-9.jpg" alt="About" />
                  </div>
                 
                </div>
              </div>
              <div className="col col-lg-6 col-md-12 col-12 about-right-item">
                <div className="about-right-text">
                  <h2 style={{fontWeight:"300",lineHeight:"45px"}}>Choose the Best Semicon Services</h2>
                  <p>
                    We are Route 2 SOC, a semiconductor solution provider
                    headquartered in Bangalore, the Silicon Valley of India. As
                    of any techie, we had a vision of creating and contributing
                    to all the products in our line of expertise. This idea is
                    the origin of us. With our expertise in SOC development
                    lifecycle and our shared thirst for technology, we were able
                    to achieve our dream and keep going for a decade now.
                  </p>
                  <p>
                    Yes, At
                    Route 2 SOC, we provide exceptional VLSI services and solutions
                    to our customers, covering the entire front end and back end
                    of ASIC. We cater to various leading enterprises of
                    Semiconductor, Automotive, Networking, Telecom, Defense and
                    Avionics industries.
                  </p>
                  <h3>OUR CULTURE :</h3>
                  <p>
                    We are a group of diverse people united with the common goal
                    of growth and passion of technology. We do have some basic
                    factors that shapes our culture here.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="choose-area-s2">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-lg-6 col-12">
            <div className="choose-text" style={{marginTop:"-50px"}}>
              <div className="ch-top-title-s2">
                <h2 style={{fontWeight:"300",lineHeight:"45px"}}>Energy Of Possibilities With Choose Systems!</h2>
                <p>
                  Our approach has been simple. Provide 100% competitive price
                  sing qualified plumbers with no hidden fees ked by a 100%
                  uarantee on workmanship Provide .
                </p>
              </div>
              {/* <div className="choose-items">
                <div className="info creative-idea">
                  <div className="info-icon">
                    <i className="icon-enargey2"></i>
                  </div>
                  <div className="info-text">
                    <h3>Versatile Career Growth</h3>
                    <p>
                      Are you provide a revolutionary level of transparency
                      solar this campaigns - from backlink acquisition.
                    </p>
                  </div>
                </div>
                <div className="info reliable-energy">
                  <div className="info-icon">
                    <i className="icon-pie"></i>
                  </div>
                  <div className="info-text">
                    <h3>Positive Team Sprit</h3>
                    <p>
                      Are you provide a revolutionary level of transparency
                      solar this campaigns - from backlink acquisition.
                    </p>
                  </div>
                </div>
                <div className="info reliable-energy">
                  <div className="info-icon">
                    <i className="icon-pie"></i>
                  </div>
                  <div className="info-text">
                    <h3>Career Stability</h3>
                    <p>
                      Are you provide a revolutionary level of transparency
                      solar this campaigns - from backlink acquisition.
                    </p>
                  </div>
                </div>
                <div className="info reliable-energy">
                  <div className="info-icon">

                    <i className="icon-pie"></i>
                  </div>
                  <div className="info-text">
                    <h3>Employee friendly work life balance</h3>

                    <p>
                      Are you provide a revolutionary level of transparency
                      solar this campaigns - from backlink acquisition.
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="choose-items">
                <div className="info creative-idea">
                  <div className="info-icon">
                    <i class="fa fa-bullseye" aria-hidden="true"></i>
                  </div>
                  <div className="info-text">
                    <h3>MISSION</h3>
                    <p>
                    Constantly improve our expertise, become the leading partner in engineering services, by providing quality & timely delivery to our clients, to make better products and a better world.
                    </p>
                  </div>
                </div>
                <div className="info reliable-energy">
                  <div className="info-icon">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </div>
                  <div className="info-text">
                    <h3>VISION</h3>
                    <p>
                    Every product in the world from our service segments, must have our contribution in it.
                    </p>
                  </div>
                </div>
                <div className="info reliable-energy">
                  <div className="info-icon">
                    <i class="fa fa-industry" aria-hidden="true"></i>
                  </div>
                  <div className="info-text">
                    <h3>VALUES</h3>
                    <ul style={{listStyle:"inside"}}>
                      <li>Customer Focus</li>
                      <li>Value People</li>
                      <li>Integrity</li>
                      <li>Passion for Winning</li>
                      <li>Constant Improvement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-6 col-12">
            <div className="choose-img">
              <img src="assets/images/choose-left.jpg" style={{width:"80%", borderRadius:"50px"}} alt="" />
              
            </div>
          </div>
        </div>
      </div>
    </section>
      {/* <!-- end of choose-area  --> */}

    </div>
  );
};

export default About;
