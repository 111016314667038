import React from 'react'
import { Link } from "react-router-dom";


const Training = () => {
  return (
    <div>
     {/* <!-- start page-title --> */}
     <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Training</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Training</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}

      <div className="service-single-page ptb-120">
        <div className="container-fluid">
          <div className="ch-top-title-s3">
            <h2 style={{ fontWeight: "300" }}>
              Getting The Students Industry Ready
            </h2>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="col bg-info"
                style={{
                  // backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Selective Training</h3>
                <p style={{ textAlign: "center" }}>
                  Students with multiple persona are trained according to their
                  strengths & weaknesses.
                </p>
              </div>
              <div
                class="col"
                style={{
                  backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Expert Instructors</h3>
                <p style={{ textAlign: "center" }}>
                  Radiating the ultimate domain expertise from the Industry
                  Experts.
                </p>
              </div>
              <div
                class="col bg-warning"
                style={{
                  // backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Lifetime Learning</h3>
                <p style={{ textAlign: "center" }}>
                  Job-oriented Learning that sets the career for lifetime.
                </p>
              </div>
            </div>
          </div>
          <br></br>
          <div className="service-single-top">
            <div className="row">
              <div className="col-lg-9">
                <div className="service-single-img mt-5">
                  {/* <img src="assets/images/service-single/single.jpg" alt="" /> */}

                  <h2 style={{ textAlign: "center" }}> Our Courses</h2>
                  <br />
                  <p
                    style={{
                      width: "80%",
                      margin: "auto",
                      textAlign: "center",
                    }}
                  >
                    The state-of-the-art infrastructure of ROUTE2SOC can
                    accommodate ~ 1000 students per year (300 per batch at a
                    time) to train students in multiple domains like Physical
                    Design, Analog Layout, Design Verification & Embedded
                    Systems.
                  </p>
                  <div
                    class="card"
                    style={{
                      width: "17rem",
                      margin: "10px",
                      float: "left",
                    }}
                  >
                    <img
                      src="assets/images/courses/vlsi-physical-design.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI PHYSICAL DESIGN
                      </h3>
                      <p class="card-text">
                        Be that Physical Designer with the world-class
                        industry-oriented VLSI – Physical Design training
                        program using the Cadence Encounter tool.
                      </p>
                      <Link to="/VLSI-physical-design">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{ width: "17rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/vlsi-analog-layout.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI ANALOG LAYOUT
                      </h3>
                      <p class="card-text">
                        Be that Analog Layout Designer with the world-class
                        industry-oriented training program using the Cadence
                        Virtuoso tool.
                      </p>
                      <Link to="/VLSI-analog-layout">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card mb-5"
                    style={{ width: "17rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/VLSI-Design-Verification.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI Design Verification
                      </h3>
                      <p class="card-text">
                        Bridging innovation with precision through
                        industry-grade VLSI Design Verification training using
                        Cadence & Mentor Graphics tools.
                      </p>
                      <Link to="/VLSI-desgin-verification">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{ width: "17rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/VLSI-Embedded-Systems.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI Embedded Systems
                      </h3>
                      <p class="card-text">
                        Bring the change by joining the world-class
                        industry-oriented Embedded Systems training program
                        offered by ROUTE2SOC.
                      </p>
                      <Link to="/VLSI-Embeded-systems">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <br></br>
                <div className="service-single-category">
                  <h3>Key aspects and stages of VLSI design:</h3>
                  <ul>
                    <li>
                      <a href="#">Specification</a>
                    </li>
                    <li>
                      <a href="#">Architectural Design</a>
                    </li>
                    <li>
                      <a href="#">Logic Design</a>
                    </li>
                    <li>
                      <a href="#">Circuit Design</a>
                    </li>
                    <li>
                      <a href="#">Physical Design</a>
                    </li>
                    <li>
                      <a href="#">Verification</a>
                    </li>
                    {/* <li>
                      <a href="#">Manufacturingn</a>
                    </li> */}
                    <li>
                      <a href="#">Testing and Debugging</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Training