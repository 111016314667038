import React from 'react';

const ASICRTLdesign = () => {
  return (
    <div>
     {/* <!-- start page-title --> */}
     <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>ASIC/ RTL Design</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>ASIC/ RTL Design</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img src="assets/images/service/ASIC.jpg" alt="" />

                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="">ASIC / RTL Design</a>
                                    </h2>
                                    <p>ROUTE2SOC offers comprehensive ASIC/RTL Design Services, leveraging cutting-edge tools, technologies, and expertise. Our offerings include Micro-architecture development, RTL Design, Linting, CDC, LEC, and Synthesis.</p>
                                    <p>With extensive experience in developing complex Design IPs, Subsystems, and SoCs, we excel across industry verticals like Processors, Mobile Communications, IoT, 5G, and Multimedia.</p>
                                    <p>Our design experts have a proven track record of successful tapeouts, showcasing their proficiency in IP Design and SoC Integration.</p>
                                    <p>Key services:</p>
                                    <p>
                                      <ul>
                                        <li>RTL implementation using VHDL, Verilog, and SystemVerilog.</li>
                                        <li>Industry-leading tools like Spyglass for Linting and CDC flows</li>
                                        <li>GLS setup and troubleshooting, including X-propagation issues.</li>
                                        <li>Expertise in Synthesis, Static Timing Analysis (STA), and LEC.</li>
                                      </ul>
                                    </p>
                                    <p>Committed to delivering high-quality design solutions that meet industry standards, we are the trusted partner for semiconductor design companies, driving their success in the ever-evolving tech landscape.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/design-for-testability"><span><i class="icon-arrow"></i>Design for Testability </span> </a></li>
                                    <li><a href="/ASIC-RTL-design"><span><i class="icon-arrow"></i>ASIC/ RTL Design</span></a></li>
                                    <li><a href="/PhysicalDesign-STA"><span><i class="icon-arrow"></i>Physical Design & STA </span> </a></li>
                                    <li><a href="/FPGA-design"><span><i class="icon-arrow"></i>FPGA Design </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}
    </div>
  );
}

export default ASICRTLdesign;
