import React from 'react'

const Embededservices = () => {
  return (
    <div>
       <section
        className="ch-blog-area "
        style={{ backgroundImage: "url('assets/images/h2-bg-section-02.jpg')" }}
      >
        <div className="container">
          <br></br>
          <br></br>
          <div className="ch-top-title-s3">
            <h2 style={{ fontWeight: "300" }}>
              Our Customized Embedded Services
            </h2>
          </div>
          <div className="blog-items">
            <div className="row">
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="blog-item">
                  <div
                    className="blog-img middle-shade"
                    style={{ height: "100px" }}
                  >
                    <img
                      src="assets/images/service/vlsi-multimedia.jpg"
                      alt=""
                    />
                  </div>
                  <div className="blog-content">
                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        paddingTop: "30px",
                      }}
                    >
                      High-Definition Audio/Video, Advanced Camera Systems,
                      Real-Time Streaming, Efficient H.264/H.265 Encoding, Dolby
                      Audio, 4K Video Processing, AR/VR Experiences, Image
                      Signal Processing{" "}
                    </p>
                  </div>
                  <div
                    className="blog-bottom"
                    style={{
                      background: "rgb(19,9,223)",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(19,9,223,1) 5%, rgba(192,0,255,1) 100%, rgba(121,9,107,0.017331932773109293) 100%)",
                    }}
                  >
                    <h2 style={{ fontSize: "18px" }}>
                      <a href="/multimedia" style={{ color: "white" }}>
                        Multimedia{" "}
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="blog-item">
                  <div
                    className="blog-img middle-shade"
                    style={{ height: "100px" }}
                  >
                    <img
                      src="assets/images/service/vlsi-automotive.jpg"
                      alt=""
                    />
                  </div>
                  <div className="blog-content">
                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        paddingTop: "30px",
                      }}
                    >
                      AUTOSAR Compliance, ADAS Solutions, Vehicle-to-Vehicle
                      Communication, Intelligent Infotainment Systems, Embedded
                      Automotive Software, Telematics, Eco-friendly EV
                      Technology.{" "}
                    </p>
                  </div>
                  <div
                    className="blog-bottom"
                    style={{
                      background: "rgb(19,9,223)",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(19,9,223,1) 5%, rgba(192,0,255,1) 100%, rgba(121,9,107,0.017331932773109293) 100%)",
                    }}
                  >
                    <h2 style={{ fontSize: "18px" }}>
                      <a href="automotive" style={{ color: "white" }}>
                        Automotive{" "}
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="blog-item">
                  <div
                    className="blog-img middle-shade"
                    style={{ height: "100px" }}
                  >
                    <img src="assets/images/service/vlsi-modem.jpg" alt="" />
                  </div>
                  <div className="blog-content">
                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        paddingTop: "30px",
                      }}
                    >
                      5G Connectivity Solutions, High-Speed Wi-Fi, Bluetooth Low
                      Energy (BLE), LTE Advanced, RF Systems, IoT Connectivity,
                      Network Protocol Stacks, Seamless Data Transmission.{" "}
                    </p>
                  </div>
                  <div
                    className="blog-bottom"
                    style={{
                      background: "rgb(19,9,223)",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(19,9,223,1) 5%, rgba(192,0,255,1) 100%, rgba(121,9,107,0.017331932773109293) 100%)",
                    }}
                  >
                    <h2 style={{ fontSize: "18px" }}>
                      <a href="/modem" style={{ color: "white" }}>
                        Modem{" "}
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="blog-item">
                  <div
                    className="blog-img middle-shade"
                    style={{ height: "100px" }}
                  >
                    <img src="assets/images/service/vlsi-IOT.jpg" alt="" />
                  </div>
                  <div className="blog-content">
                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        paddingTop: "30px",
                      }}
                    >
                      Smart Sensor Integration, Edge Computing, IoT Data
                      Analytics, Machine-to-Machine Communication, AI at Edge,
                      Secure IoT Connectivity, 5G/LoRaWAN/NB-IoT, IoT Device
                      Management.{" "}
                    </p>
                  </div>
                  <div
                    className="blog-bottom"
                    style={{
                      background: "rgb(19,9,223)",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(19,9,223,1) 5%, rgba(192,0,255,1) 100%, rgba(121,9,107,0.017331932773109293) 100%)",
                    }}
                  >
                    <h2 style={{ fontSize: "18px" }}>
                      <a href="/internet-of-things" style={{ color: "white" }}>
                        Internet of Things{" "}
                      </a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end container --> */}
      </section>
    </div>
  )
}

export default Embededservices