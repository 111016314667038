import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Newsletter from '../Newsletter';

const Contacts = () => {
  const formRef = useRef(); // Ref for the form element

  const sendEmail = (e) => {
    e.preventDefault();

    // Extract the form data
    const formData = new FormData(formRef.current);

    // Specify your service ID, template ID, and user ID from emailjs.com
    emailjs
      .sendForm('service_tquybub', 'template_d2qd10j', formRef.current, 'Y38t_8F2K1QVz6-d0')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Email sent successfully!');
          formRef.current.reset(); // Reset the form after successful submission
        },
        (error) => {
          console.error('Email sending failed:', error.text);
          alert('Failed to send email. Please try again later.');
        }
      );
  };

  return (
    <div>
    {/* <!-- start page-title --> */}
    <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Contact Us</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Contact</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
        <div class="contact-page">
            {/* <!-- start of contact-section --> */}
            <section class="contact-section ptb-120 pt-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col col-lg-6 col-md-6 col-12">
                            <div class="contact-img">
                                <img src="assets/images/contact.png" alt=""/>
                            </div>
                        </div>
                        <div class="col col-lg-6 col-md-6 col-12">
                            <div class="contact-form-area">
                                <div class="row">
                                    <div class="col-lg-12 col-12">
                                        <div class="ch-top-title-s2">
                                            <h2 style={{fontWeight:"300",lineHeight:"45px"}}>Increase Your Caliber <br></br>With Our Expertise</h2>
                                            <p>We understand the importance of approaching each work integrally and believe in the power of simple. </p>

                                            <i class="icon-hdf"></i>
                                        </div>
                                    </div>
                                </div>
                                <form method="post" class="contact-activation" id="contact-form-mejor"
                                    novalidate="novalidate"  ref={formRef}
                                    onSubmit={sendEmail}>
                                    <div class="row">
                                        <div class="col-md-6 col-12 form-field">
                                            <input type="text" class="form-control" name="name" id="name"
                                                placeholder="Your Name" autocomplete="name"/>
                                        </div>
                                        <div class="col-md-6 col-12 form-field">
                                            <input type="text" class="form-control" name="address" id="address"
                                                placeholder="Address"/>
                                        </div>
                                        <div class="col-md-6 col-12 form-field">
                                            <input type="email" class="form-control" name="email" id="email"
                                                placeholder="Your Email"/>
                                        </div>
                                        <div class="col-md-6 col-12 form-field">
                                            <input type="text" class="form-control" name="mobile" id="phone"
                                                placeholder="Phone Number"/>
                                        </div>
                                        <div class="col-md-6 col-12 form-field">
                                            <select name="service" class="form-control">
                                                <option selected="">Select Service type?</option>
                                                <option>Semicon Services</option>
                                                <option>Embedded Services</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 col-12 form-field">
                                            <select name="course" class="form-control">
                                                <option selected="">Select Course</option>
                                                <option>VLSI PHYSICAL DESIGN</option>
                                                <option>VLSI ANALOG LAYOUT</option>
                                                <option>VLSI Design Verification</option>
                                                <option>VLSI Embedded Systems</option>
                                            </select>
                                        </div>
                                        
                                        <div class="col-md-12 col-12">
                                            <div class="submit-area">
                                                <button type="submit" class="ch-btn-style-1"><span>REQUEST
                                                        SEND</span></button>
                                                <div id="loader">
                                                    <i class="ti-reload"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix error-handling-messages">
                                        <div id="success">Thank you! Massage Sended</div>
                                        <div id="error"> Error occurred while sending email. Please try again
                                            later. </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124411.90244521541!2d77.66789198214259!3d12.9800408187299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0e0673d94851%3A0xa2b1d8c5a9fc5fef!2sAmbedkar%20Nagar%2C%20Whitefield%2C%20Bengaluru%2C%20Karnataka%20560066!5e0!3m2!1sen!2sin!4v1714650720078!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
            {/* <!-- end of contact-section  --> */}

        </div>
        <Newsletter/>
    </div>
  );
}

export default Contacts;
