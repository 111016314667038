import React from 'react';

const Gallery = () => {
  return (
    <div>
     {/* <!-- start page-title --> */}
     <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Our Gallery</h2>
                  <ol>
                    <li>
                      <a href="index.html">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Gallery</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      <footer class="footer-section">
            <div class="upper-footer">
                <div class="container">
                    <div class="row">
                        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            <div class="widget instagram">
                                <div class="widget-title">
                                    <h3> Gallery 1</h3>
                                </div>
                                <ul>
                                    <li><a href="#"><img src="assets/images/gallery/img-1.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-2.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-3.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-4.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-5.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-6.jpg" alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            <div class="widget instagram">
                                <div class="widget-title">
                                    <h3> Gallery 2</h3>
                                </div>
                                <ul>
                                    <li><a href="#"><img src="assets/images/gallery/img-1.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-2.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-3.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-4.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-5.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-6.jpg" alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                            <div class="widget instagram">
                                <div class="widget-title">
                                    <h3> Gallery 3</h3>
                                </div>
                                <ul>
                                    <li><a href="#"><img src="assets/images/gallery/img-1.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-2.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-3.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-4.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-5.jpg" /></a></li>
                                    <li><a href="#"><img src="assets/images/gallery/img-6.jpg" alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* <!-- end container --> */}
            </div>
        </footer>
    </div>
  );
}

export default Gallery;
