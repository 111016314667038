import React from "react";

const VLSIPhysicaldesign = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>VLSI Physical Design</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>VLSI Physical Design</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      {/* <!-- start blog-page-area --> */}
      <section class="blog-page-area ptb-120">
        <div class="container">
          <div class="row">
            <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
              <div class="blog-page-left">
                <div class="blog-item">
                  <div class="blog-img middle-shade">
                    {/* <img src="assets/images/blog/blog-7.jpg" alt="" /> */}
                    <img
                      src="assets/images/courses/vlsi-physical-design.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    {/* <ul>
                      <li>
                        <i class="ti-tag"></i> Power
                      </li>
                      <li>
                        <i class="ti-comments"></i> Comments (05)
                      </li>
                    </ul> */}
                  </div>
                  <div class="blog-content">
                    <h2>
                      <a href="">Course Content</a>
                    </h2>
                    <p>
                      Fundamental concepts in Digital abstraction, Static
                      discipline, MOSFET switch, CMOS basics, Digital circuit
                      speed, NMOS logic, CMOS logic, Combinational logic,
                      Sequential logic, Synchronous sequential design, Timing
                      awareness, Setup/Hold requirement significance,
                      Asynchronous circuits, Metastability, Synchronization,
                      Logic synthesis fundamentals, Advanced logic synthesis
                      (PLE based), Floor planning, Power planning, Placement,
                      Clock tree synthesis, Routing, Signal integrity, IR-drop
                      analysis, OCV analysis, Static timing analysis and
                      advanced Physical design concepts like Low power design
                      techniques. The trainees get to work on 5 to 6 different
                      designs. The assignments are designed in such a way that
                      trainees have a clear understanding about handling the
                      design from Synthesis to Sign-off within the given
                      specification limits of Area, Timing and Power.
                    </p>
                    {/* <a
                      class="ch-btn-style-3 ch-btn-animated"
                      href="blog-single.html"
                    >
                      Read More
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
              <div class="blog-sidebar">
                <div class="category-widget widget">
                  <h3>Category</h3>
                  <ul>
                    <li>
                      <a href="/VLSI-physical-design">
                        <span>
                          <i class="icon-arrow"></i>VLSI PHYSICAL DESIGN{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-analog-layout">
                        <span>
                          <i class="icon-arrow"></i>VLSI ANALOG LAYOUT{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-desgin-verification">
                        <span>
                          <i class="icon-arrow"></i>VLSI Design Verification{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-Embeded-systems">
                        <span>
                          <i class="icon-arrow"></i>VLSI Embedded Systems{" "}
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
             

              <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                     Exam Syllabus
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    You need to qualify the screening test and technical interview. The test would be conducted in Basic Electronics – BJT, FET, CMOS; Digital Electronics – Number Systems, Boolean Algebra, K-Maps, Logic Gates, Logic Families, Combinational Circuits, Sequential Circuits, and Counters. (All are subjective type questions)
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                   Qualification
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    B.E/B.Tech in ECE/EEE, M.E/M.Tech/M.S in VLSI/Embedded Systems/Digital Electronics.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                     Academics
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Aggregate of 65% & Above
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                    No. of Seats
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    80
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
               Fee
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    ₹1,50,000/- [+ 18% GST].
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end blog-section --> */}
    </div>
  );
};

export default VLSIPhysicaldesign;
