import React from "react";
import './Slider.css'

const Slider = () => {
  return (
    <div>
      <div 
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active slidebuttons"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
            class="slidebuttons"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
            class="slidebuttons"
          ></button>
        </div>
        <div class="carousel-inner">
         
          <div class="carousel-item active">
            <img
              src="assets/images/hero/bg-shape-3.jpg"
              class="d-block w-100"
              style={{ height: "auto", width:"100%" }}
              alt="slider3"
            />{" "}
            <div
              class="sliderText"
              style={{
                width: "40%",
                height: "aoto",
                // border: "2px solid green",
                float: "left",
                position: "absolute",
                top: "150px",
                left: "150px",
              }}
            >
              <div className="ch-main-hero-text">
                {" "}
                <h2
                  className="fadeInUp headtext"
                  style={{
                    fontSize: "32px",
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                  <span style={{ color: "white" }} >
                    We are a Leader in Semiconductor Engineering Services
                  </span>{" "}
                </h2>{" "}
                <p className="fadeInUp maintext" style={{ color: "white" }}>
                  Semiconductor testing services ensure that chips meet quality
                  and performance standards. Bring the change by joining the
                  world-class industry-oriented Semicon Services training
                  program offered by ROUTE2SOC.
                </p>{" "}
                <div className="hero-btn">
                  {" "}
                  <a href="/semiconservices" className="ch-btn-style-2">
                    Read More{" "}
                  </a>{" "}
                  {/* <a
                               href="service.html"
                               className="ch-btn-style-1 ch-btn-animated"
                             >
                               Our Services
                             </a> */}{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="assets/images/hero/bg-shape-4.jpg"
              class="d-block w-100"
              style={{ height: "auto", width:"100%" }}
              alt="slider3"
            />{" "}
            <div
              class="sliderText"
              style={{
                width: "40%",
                height: "aoto",
                // border: "2px solid green",
                float: "left",
                position: "absolute",
                top: "150px",
                left: "150px",
              }}
            >
              <div className="ch-main-hero-text">
                {" "}
                <h2
                  className="fadeInUp headtext"
                  style={{
                    fontSize: "32px",
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                  <span >
                    Embedded systems - Smart, connected, secure, and
                    power-efficient
                  </span>{" "}
                </h2>{" "}
                <p className="fadeInUp maintext" style={{ color: "white" }}>
                  Bring the change by joining the world-class industry-oriented
                  Embedded Systems training program offered by ROUTE2SOC.
                </p>{" "}
                <div className="hero-btn">
                  {" "}
                  <a href="/embededservices" className="ch-btn-style-2">
                    Read More{" "}
                  </a>{" "}
                  {/* <a
                               href="service.html"
                               className="ch-btn-style-1 ch-btn-animated"
                             >
                               Our Services
                             </a> */}{" "}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
          <div class="carousel-item ">
            <img
              src="assets/images/hero/bg-shape.jpg"
              class="d-block w-100"
              style={{ height: "auto", width:"100%" }}
              alt="slider3"
            />
            <div
              class="sliderText"
              style={{
                width: "40%",
                height: "aoto",
                // border: "2px solid green",
                float: "left",
                position: "absolute",
                top: "150px",
                left: "150px",
              }}
            >
              <div className="sliderText">
              <div className="ch-main-hero-text">
                {" "}
                <h2
                  className="fadeInUp headtext"
                  style={{
                    fontSize: "32px",
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                   <span> End to End Application Specific SOC Choice!</span>{" "}
                </h2>{" "}
                <p className="fadeInUp maintext" style={{ color: "white" }}>
                  We enable our customers to broaden their reach to cost
                  sensitive markets, with our ability to participate in complex
                  projects throughout the entire chip design cycle. Our strong
                  team with core values of trust, integrity and excellence have
                  enabled multitude of projects.{" "}
                </p>{" "}
                <div className="hero-btn">
                  {" "}
                  <a href="/training" className="ch-btn-style-2">
                    Read More{" "}
                  </a>{" "}
                  {/* <a
                               href="service.html"
                               className="ch-btn-style-1 ch-btn-animated"
                             >
                               Our Services
                             </a> */}{" "}
                </div>{" "}
              </div>{" "}
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Slider;
