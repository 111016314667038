import React from 'react';

const Footer = () => {
  return (
    <div>
       <footer class="footer-section">
            <div class="upper-footer">
                <div class="container">
                    <div class="row">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="widget about-widget">
                                   <a href='/'> <img src="assets/images/logo.png" style={{width:"450px"}} alt="logo"/> </a>
                               
                                <p>We work with a passion of taking challenges and creating new ones in Semicon & Embedded sectors</p>
                                
                            </div>
                        </div>
                        <div class="col col-xl-6 col-lg-2 col-md-6 col-sm-12 col-12">
                            <div class="widget link-widget">
                                <div class="widget-title">
                                    <h3>Follow Us</h3>
                                </div>
                                <div class="widget social-widget p-0">
                                    <ul>
                                        <li><a href="#" style={{marginTop:"5px"}}><i class="ti-facebook" ></i></a></li>
                                        <li><a href="#"><i class="ti-pinterest"></i></a></li>
                                        <li><a href="#"><i class="ti-twitter-alt"></i></a></li>
                                        <li><a href="#"><i class="ti-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div class="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div class="widget instagram">
                                <div class="widget-title">
                                    <h3>Our Gallery</h3>
                                </div>
                                <ul>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-1.jpg" /></a></li>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-2.jpg" /></a></li>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-3.jpg" /></a></li>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-4.jpg" /></a></li>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-5.jpg" /></a></li>
                                    <li><a href="project-single.html"><img src="assets/images/gallery/img-6.jpg" alt=""/></a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
                 {/* <!-- end container --> */}
            </div>
            <div class="lower-footer">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col col-lg-6 col-12">
                            <div class="copy-right">
                                <p class="copyright"> &copy; 2024 <a href="/">ROUTE2SOC TECHNOLOGIES</a>, All
                                    Rights
                                    Reserved.</p>
                            </div>
                        </div>
                        <div class="col col-lg-6 col-12">
                            <ul class="lower-footer-link">
                                <li><a href="/">Home</a></li>
                                <li><a href="/About">About Us</a></li>
                                <li><a href="/semiconservices">Semicon Sercices</a></li>
                                <li><a href="/Embededservices">Embded Services</a></li>
                                <li><a href="/contacts">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  );
}

export default Footer;
