import React, { useEffect } from 'react';
import './Features.css'; // Import CSS file for styles

const Features = () => {
  

  return (
    <div>
      <section className="container-fluid">
        <div className="container-fluid">
          <div className="features-wrap" >
            <div className="row">
                  <div class="container-fluid" 
                  style={{backgroundImage:"url('assets/images/bg.jpg')",
                  color:"white", 
                  height:"auto", 
                  fontSize:"26px"}}>
                      <div class="row">
                        <div class="col-sm">
                        END-TO-END SEMICONDUCTOR SERVICES
                        </div>
                        <div class="col-sm">
                          <img src='assets/images/hero/asic-icon.png' />
                        </div>
                        <div class="col-sm">
                        <img src='assets/images/hero/Analog-Mixed-Signal.png' />
                        </div>
                        <div class="col-sm">
                        <img src='assets/images/hero/custom-layout-1.png' />
                        </div>
                        <div class="col-sm">
                        <img src='assets/images/hero/Technology.png' />
                        </div>
                        <div class="col-sm">
                        <img src='assets/images/hero/Analog-Mixed-Signal.png' />
                        </div>
                      </div>
                      
                   </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
