import React from 'react';

const FPGAdesign = () => {
  return (
    <div>
{/* <!-- start page-title --> */}
<section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>FPGA Design</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>FPGA Design</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img src="assets/images/service/FPGPA.jpg" alt="" />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="">FPGA Design</a>
                                    </h2>
                                    <p>ROUTE2SOC brings extensive expertise in developing complex Design IPs and Subsystems for FPGA devices. Our experienced team handles Verilog designs and implements FPGA designs on Xilinx devices like Spartan, Virtex, Zynq-7000, and Zynq-Ultrascale.</p>
                                    <p>Our comprehensive FPGA Design Services include:</p>
                                    <p>
                                      <ul>
                                        <li>RTL implementation using VHDL, Verilog, and SystemVerilog.</li><br></br>
                                        <li>Custom IP development and IP core interfacing.</li><br></br>
                                        <li>Xilinx SDK embedded application development for Zynq-7000 SoCs and Zynq UltraScale+ MPSoC.</li><br></br>
                                        <li>System debugging support for MicroBlaze cores, Zynq-7000 SoCs, and Zynq UltraScale+ MPSoC.</li><br></br>
                                        <li>High-speed interface development for AXI, AXI-4, and 10G Ethernet PHY.</li><br></br>
                                        <li>Specification development, IP core selection, and architecture design.</li><br></br>
                                        <li>Design of data and interface control modules, data processing algorithms, and custom module development.</li><br></br>
                                        <li>FPGA and MPSoC/RFSoC development.</li><br></br>
                                        <li>Memory management support for MPSoC.</li><br></br>
                                        <li>Real-time FPGA application support.</li><br></br>
                                        <li>FPGA optimization of existing solutions.</li><br></br>
                                      </ul>
                                    </p>
                                    <p>Additionally, our vendor-independent IP cores offer flexibility in FPGA design choices, empowering our clients with diverse options.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/design-for-testability"><span><i class="icon-arrow"></i>Design for Testability </span> </a></li>
                                    <li><a href="/ASIC-RTL-design"><span><i class="icon-arrow"></i>ASIC/ RTL Design</span></a></li>
                                    <li><a href="/PhysicalDesign-STA"><span><i class="icon-arrow"></i>Physical Design & STA </span> </a></li>
                                    <li><a href="/FPGA-design"><span><i class="icon-arrow"></i>FPGA Design </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default FPGAdesign;
