import React from 'react';

const Automotive = () => {
  return (
    <div>
{/* <!-- start page-title --> */}
<section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Automotive</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Automotive</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img
                      src="assets/images/service/vlsi-automotive.jpg"
                      alt=""
                    />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2>Automotive
                                    </h2>
                                    <p>ROUTE2SOC offers comprehensive Automotive solutions, empowering the future of intelligent transportation systems and connected cars. With expertise in automotive technologies, we deliver innovative solutions for enhanced safety, infotainment, and eco-friendly driving experiences.</p>
                                    <h5>Key Services:</h5>
                                    <ul>
                                      <li>Intelligent Transportation Systems (ITS): We develop advanced solutions for smart traffic management, vehicle-to-vehicle communication, and intelligent transportation infrastructure.</li> <br></br>
                                      <li>Vehicle Telematics: Our experts specialize in telematics solutions, enabling real-time vehicle tracking, remote diagnostics, and fleet management for enhanced efficiency and safety.</li><br></br>
                                      <li>Autonomous Driving Solutions: We provide expertise in developing autonomous driving technologies, including sensor integration, perception algorithms, and vehicle control systems.</li><br></br>
                                      <li>In-Vehicle Infotainment (IVI): Our solutions encompass multimedia integration, connectivity features, and intuitive user interfaces to create engaging in-car infotainment experiences.</li><br></br>
                                      <li>Safety & Security Enhancements: We develop solutions for active and passive safety systems, cybersecurity measures, and robust vehicle network architectures.</li><br></br>
                                    </ul>

                                    <h5>Tools and Technologies:</h5>
                                    <p>We leverage industry-leading tools and technologies such as AUTOSAR, ADAS, CAN bus, Ethernet, and functional safety standards like ISO 26262 to develop cutting-edge automotive solutions. Our expertise extends to programming languages like C, C++, and Python for efficient software development.</p>
                                    <p>At ROUTE2SOC, we are committed to delivering innovative automotive solutions that redefine the driving experience. Partner with us to drive the future of connected and autonomous vehicles, transforming the automotive industry.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/multimedia"><span><i class="icon-arrow"></i>Multimedia </span> </a></li>
                                    <li><a href="/automotive"><span><i class="icon-arrow"></i>Automotive</span></a></li>
                                    <li><a href="/modem"><span><i class="icon-arrow"></i>Modem </span> </a></li>
                                    <li><a href="/internet-of-things"><span><i class="icon-arrow"></i>Internet of Things </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default Automotive;
