import React, { useEffect } from "react";
import "./header.css";

const Header = () => {
  useEffect(() => {
    const navbar = document.getElementById("navbar");
    const sticky = navbar.offsetTop;

    const handleScroll = () => {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="header-main-wrap">
        <div className="topbar">
          <h2 className="hidden">some</h2>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col col-lg-7 col-md-12 col-12">
                <div className="contact-intro">
                  <ul>
                    <li>
                      <i className="icon-envolpe"></i> info@route2soc.com
                    </li>
                    {/* <li>
                      <i className="icon-call"></i> +91 6301 767 711
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col col-lg-5 col-md-12 col-12">
                <div className="topbar-right">
                  <div className="contact-info social">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="icon-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icon-xicon"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icon-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
          <div className="container">
            <a href="/">
              <img
                src="assets/images/logo.png"
                alt="logo"
                style={{ width: "300px" }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarTogglerDemo02"
              style={{ float: "right", marginLeft: "100px" }}
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active nav-item-hover"
                    aria-current="page"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active nav-item-hover"
                    aria-current="page"
                    href="/about"
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/semiconservices"
                  >
                    Semicon Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/embededservices"
                  >
                    Embedded Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/training"
                  >
                    Training
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/contacts"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
