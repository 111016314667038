import React from 'react';

const Products = () => {
  return (
    <div>
       {/* <!-- start page-title --> */}
        <section class="page-title">
            <div class="container-fluid">
                <div class="page-title-wrap">
                    <div class="row align-items-center">
                        <div class="col col-md-6 col-12">
                            <div class="breadcumb-wrap">
                                <h2>Products</h2>
                                <ol>
                                    <li><a href="index.html"><i class="icon-36"></i> Home</a></li>
                                    <li>Products</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col col-lg-6">
                            <div class="breadcumb-img">
                                <img src="assets/images/page-title-img.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end page-title --> */}
        {/* <!-- start of project-section --> */}
        <section className="project-section ptb-120">
            <div className="container">
                <div className="row align-items-center project-gallery">
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="ch-top-title-s2">
                            <span>OUR BEST PRODUCTS</span>
                            <h2>Our Project Work Flow Energize Solar Plan</h2>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12">
                        <div className="project-filters-btn">
                            <p>Our approach has been simple. Provide 100% competitive price sing qualified plumber with no hidden fees ked by a 100% uarantee workmanship Provide. </p>
                            <ul>
                                <li><a data-filter="*" href="#" className="current">All</a></li>
                                <li><a data-filter=".Solar" href="#">Solar Energy</a></li>
                                <li><a data-filter=".Client" href="#">Client Satisfied</a></li>
                                <li><a data-filter=".Support" href="#">Support</a></li>
                                <li><a data-filter=".Materials" href="#">Materials</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12 project-gallery">
                        <div className="project-container masonry-gallery row">
                            <div className="col col-lg-6 col-md-6 col-12 Client Solar">
                                <div className="project-item">
                                    <div className="project-img">
                                        <a className="project-image-popup" href="assets/images/project/project-1.jpg">
                                            <img src="assets/images/project/project-1.jpg" alt="Project 1" className="img img-responsive"/>
                                            <i className="icon-zoom"></i>
                                        </a>
                                    </div>
                                    <div className="project-text">
                                        <span>RESEARCH & ANALYSIS</span>
                                        <h2>Creative Installation Solar</h2>
                                        <a href="project-single.html">More About Us</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-6 col-12 Solar Support Materials">
                                <div className="project-item">
                                    <div className="project-img">
                                        <a className="project-image-popup" href="assets/images/project/project-2.jpg">
                                            <img src="assets/images/project/project-2.jpg" alt="Project 2" className="img img-responsive"/>
                                            <i className="icon-zoom"></i>
                                        </a>
                                    </div>
                                    <div className="project-text">
                                        <span>RESEARCH & ANALYSIS</span>
                                        <h2>How Used Solar Energy</h2>
                                        <a href="project-single.html">More About Us</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-6 col-12 Client">
                                <div className="project-item">
                                    <div className="project-img">
                                        <a className="project-image-popup" href="assets/images/project/project-3.jpg">
                                            <img src="assets/images/project/project-3.jpg" alt="Project 3" className="img img-responsive"/>
                                            <i className="icon-zoom"></i>
                                        </a>
                                    </div>
                                    <div className="project-text">
                                        <span>RESEARCH & ANALYSIS</span>
                                        <h2>Creative Installation Solar</h2>
                                        <a href="project-single.html">More About Us</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-6 col-md-6 col-12 Solar Support Materials">
                                <div className="project-item">
                                    <div className="project-img">
                                        <a className="project-image-popup" href="assets/images/project/project-4.jpg">
                                            <img src="assets/images/project/project-4.jpg" alt="Project 4" className="img img-responsive"/>
                                            <i className="icon-zoom"></i>
                                        </a>
                                    </div>
                                    <div className="project-text">
                                        <span>RESEARCH & ANALYSIS</span>
                                        <h2>Creative Installation Solar</h2>
                                        <a href="project-single.html">More About Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end row --> */}
            </div>
        </section>
            {/* <!-- end of project-section  --> */}

            <div class="project-single ptb-120">

            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <div class="project-details">
                            <h2>In an era where sustainability is paramount, the spotlight shines brightly on solar
                                energy. Harnessing the power of the sun</h2>
                            <p>Aliquam eros justo, posuere loborti vive rr laoreet matti ullamc orper posu ere
                                viverra .Aliquam eros justo, posuere loborti, vive rra laoreet augue mattis
                                fermentum ullamcorper viverra laoreet Aliquam eros justo, posuere loborti
                                viverra laoreet mat ullamcorper posue Aliquam eros justo, posre lobortis non
                                laoreet augue mattis fermentum laoreet augue mattis fermentum ullamcorper
                                viverra </p>
                            <div class="details-img">
                                <img src="assets/images/project-single/img-1.jpg" alt=""/>
                            </div>
                            <div class="details-text">
                                <h2>Embracing Solar Energy</h2>
                                <p>Aliquam eros justo, posuere loborti vive rra laoreet matti ullamc orper posu
                                    ere viverra .Aliquam eros justo, posuere lobortis non, vive rra laoreet
                                    augue s ferentum ullamcorper viverra laoreet Aliquam eros justo, posuere
                                    loborti viverra laoreet mat ullamcorper posue loborti viveAliquam eros
                                    justo, posuere loborti vive rra laoreet </p>
                                <p>Aliquam eros justo, posuere loborti vive rra laoreet matti ullamc orper posu
                                    ere viverra .Aliquam eros justo, posuere lobortis non, vive rra laoreet
                                    augue s ferAliquam eros justo, posuere loborti vive rra laoreet matti ullamc
                                    orper posu ere viverra .Aliquam eros justo, posuere lobortis non, vive rra
                                    laoreet augue s ferentum ullamcorper </p>
                                <div class="details-items">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <img src="assets/images/project-single/img-2.jpg" alt=""/>
                                            <h4>Solar energy is inexhaustible.</h4>
                                            <p>Aliquam eros justo, posuere loborti vive rra laoreet matti ullamc
                                                orper posu ere viverra .Aliquam eros justo, posuere lobortis
                                                non, vive rra laoreet augue s ferentum ullamcorper viverra
                                                laoreet Aliquam</p>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <img src="assets/images/project-single/img-3.jpg" alt=""/>
                                            <h4>Solar panels can be installed</h4>
                                            <p>Aliquam eros justo, posuere loborti vive rra laoreet matti ullamc
                                                orper posu ere viverra .Aliquam eros justo, posuere lobortis
                                                non, vive rra laoreet</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form-area">
                            <form method="post" class="contact-activation" id="contact-form-mejor"
                                novalidate="novalidate">
                                <div class="row">
                                    <div class="col-md-12 col-12">
                                        <h3>Let's Discuss install a Solar Panel!</h3>
                                    </div>
                                    <div class="col-md-6 col-12 form-field">
                                        <input type="text" class="form-control" name="name" id="name"
                                            placeholder="Your Name" autocomplete="name"/>
                                    </div>
                                    <div class="col-md-6 col-12 form-field">
                                        <input type="email" class="form-control" name="email" id="email"
                                            placeholder="Your Email"/>
                                    </div>
                                    <div class="col-md-6 col-12 form-field">
                                        <input type="text" class="form-control" name="phone" id="phone"
                                            placeholder="Phone Number"/>
                                    </div>
                                    <div class="col-md-6 col-12 form-field">
                                        <select name="subject" class="form-control">
                                            <option disabled="disabled" selected="">Choose a Option</option>
                                            <option>Installation</option>
                                            <option>Solar</option>
                                            <option>Energy</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 col-12 form-field">
                                        <textarea class="form-control" name="note" id="note"
                                            placeholder="Message here.."></textarea>
                                    </div>
                                    <div class="col-md-12 col-12">
                                        <div class="submit-area">
                                            <button type="submit" class="ch-btn-style-2"><span>Appointment
                                                    Now</span></button>
                                            <div id="loader">
                                                <i class="ti-reload"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix error-handling-messages">
                                    <div id="success">Thank you! Massage Sended</div>
                                    <div id="error"> Error occurred while sending email. Please try again
                                        later. </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="project-sidebar">
                            <div class="search-widget">
                                <form>
                                    <div>
                                        <input type="text" class="form-control" placeholder="Search Here"/>
                                        <button type="submit"><i class="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>
                            <div class="details-widget">
                                <div class="details-widget-item">
                                    <span>Client</span>
                                    <h4>Kanai Lal Tira</h4>
                                </div>
                                <div class="details-widget-item">
                                    <span>150000 USD</span>
                                    <h4>Solar panels installation</h4>
                                </div>
                                <div class="details-widget-item">
                                    <span>Category</span>
                                    <h4>Solar, energey</h4>
                                </div>
                                <div class="details-widget-item">
                                    <span>Date</span>
                                    <h4>November 19, 2024</h4>
                                </div>
                            </div>
                            <div class="service-single-category">
                                <h3>Service List:</h3>
                                <ul>
                                    <li><a href="#">Solar facilisis Service</a></li>
                                    <li><a href="#">Solar Meta-Services</a></li>
                                    <li><a href="#">Medical Of Best Service</a></li>
                                    <li><a href="#">Facilisis Commercial Service</a></li>
                                    <li><a href="#">Best facilisis Service</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}

export default Products;
