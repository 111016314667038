import React from 'react';

const Multimedia = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Multimedia</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Multimedia</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img
                      src="assets/images/service/vlsi-multimedia.jpg"
                      alt=""
                    />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="multimedia">Multimedia</a>
                                    </h2>
                                    <p>ROUTE2SOC provides comprehensive Multimedia services, specializing in audio, video, codecs, and camera solutions. Leveraging cutting-edge tools and technologies, we deliver immersive multimedia experiences with superior performance and quality.</p>
                                    <h5>Key Services:</h5>
                                    <ul>
                                      <li>Audio Solutions: We develop advanced audio processing algorithms, codec integration, and audio driver development for enhanced audio performance.</li> <br></br>
                                      <li>Video Solutions: Our experts excel in video processing, including encoding, decoding, transcoding, and streaming solutions for high-quality video playback and delivery.</li><br></br>
                                      <li>Codecs Integration: We integrate industry-standard codecs such as AAC, MP3, H.264, H.265, VP9, and AV1 to ensure efficient compression and playback of audio and video content.</li><br></br>
                                      <li>Camera Systems: Our team has extensive experience in developing camera systems, including image processing algorithms, camera driver development, and camera firmware integration.</li><br></br>
                                      <li>Multimedia Middleware: We provide middleware solutions for multimedia applications, enabling seamless integration of audio, video, and camera functionalities into software applications.</li><br></br>
                                      
                                    </ul>
                                    <h5>Tools and Technologies:</h5>
                                    <p>We leverage industry-leading tools like FFmpeg, GStreamer, OpenCV, and multimedia frameworks such as Android Multimedia Framework and DirectShow for multimedia development. Our expertise extends to programming languages like C, C++, and Python to optimize performance and functionality.</p>
                                    <p>At ROUTE2SOC, we are committed to delivering cutting-edge multimedia solutions that provide immersive audio, video, and camera experiences. Trust us as your reliable partner for multimedia development, empowering you to deliver exceptional multimedia applications and products</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/multimedia"><span><i class="icon-arrow"></i>Multimedia </span> </a></li>
                                    <li><a href="/automotive"><span><i class="icon-arrow"></i>Automotive</span></a></li>
                                    <li><a href="/modem"><span><i class="icon-arrow"></i>Modem </span> </a></li>
                                    <li><a href="/internet-of-things"><span><i class="icon-arrow"></i>Internet of Things </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}
    </div>
  );
}

export default Multimedia;
