import React from 'react';

const Modem = () => {
  return (
    <div>
{/* <!-- start page-title --> */}
<section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Modem</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Modem</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img src="assets/images/service/vlsi-modem.jpg" alt="" />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="/">Modem</a>
                                    </h2>
                                    <p>ROUTE2SOC offers comprehensive Modem services, delivering cutting-edge solutions for wireless and wired connectivity technologies such as WiFi, Bluetooth Low Energy (BLE), 5G, Narrowband IoT (NB-IoT), and Ethernet. Our expertise in these technologies enables us to provide tailored modem solutions for various IoT and networking applications.</p>
                                    <h5>Key Services:</h5>
                                    <ul>
                                      <li>WiFi Modem Solutions: We develop WiFi modems for reliable and high-speed wireless connectivity, facilitating seamless data transfer and communication in IoT deployments</li><br></br>
                                      <li>BLE Modem Solutions: Our team specializes in developing Bluetooth Low Energy (BLE) modems, enabling energy-efficient wireless communication for IoT devices and applications.</li><br></br>
                                      <li>5G Modem Solutions: We offer advanced 5G modem design and development services, providing ultra-fast and low-latency wireless connectivity for next-generation IoT deployments.</li><br></br>
                                      <li>NB-IoT Modem Solutions: Our experts design and optimize NB-IoT modems, catering to low-power, wide-area IoT applications that require long-range connectivity and extended battery life.</li><br></br>
                                      <li>Ethernet Modem Solutions: We deliver robust modem solutions for Ethernet connectivity, enabling reliable and high-speed wired communication in networking and industrial applications.</li><br></br>
                                      <li>Protocol Development: Our team excels in developing wireless and wired communication protocols, ensuring seamless interoperability and compliance with industry standards.</li><br></br>
                                      <li>Integration and Testing: We seamlessly integrate modem solutions into client devices, performing rigorous testing to ensure optimal performance, reliability, and adherence to wireless and wired standards.</li><br></br>
                                    </ul>
                                    <h5>Tools and Technologies:</h5>
                                    <p>Leveraging cutting-edge tools and technologies, including software-defined radio (SDR) platforms, wireless protocol stacks, simulation tools, and Ethernet frameworks, we develop modem solutions that meet the demands of modern IoT and networking environments.</p>
                                    <p>At ROUTE2SOC, we are committed to delivering high-quality modem solutions, enabling seamless wireless and wired connectivity across a wide range of IoT, networking, and industrial applications.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/multimedia"><span><i class="icon-arrow"></i>Multimedia </span> </a></li>
                                    <li><a href="/automotive"><span><i class="icon-arrow"></i>Automotive</span></a></li>
                                    <li><a href="/modem"><span><i class="icon-arrow"></i>Modem </span> </a></li>
                                    <li><a href="/internet-of-things"><span><i class="icon-arrow"></i>Internet of Things </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default Modem;
