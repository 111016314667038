import React from "react";

const Newsletter = () => {
  return (
    <div>
      <section class="ch-newsletter-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-12">
              <div
                class="ch-newsletter-wrap"
                style={{
                  background:
                    "url(assets/images/newslatter-bg-1.jpg) no-repeat center top",
                  backgroundSize: "cover",
                  width: "100%", // Adjust width as needed
                  height: "300px", // Adjust height as needed
                  // Add other styles as needed
                }}
              >
                <div class="ch-newsletter-inner">
                  <h2>Subscribe Your newsletter</h2>
                  <p>
                    Our approach to itis unique around know work an we know.
                  </p>
                  <div class="newsletter-form">
                    <form action="#">
                      <div class="form-field">
                        <input
                          type="email"
                          placeholder="Your email"
                          id="semail"
                          required
                        />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-12">
              <div
                class="ch-newsletter-wrap style-2"
                style={{
                  background:
                    "url(assets/images/newslatter-bg-2.jpg) no-repeat center top",
                  backgroundSize: "cover",
                  width: "100%", // Adjust width as needed
                  height: "300px", // Adjust height as needed
                  // Add other styles as needed
                }}
              >
                <div class="c-btn">
                  <a href="contact.html" class="ch-btn-style-1">
                    Contact Us
                  </a>
                </div>
                <div class="middle-btn wow zoomIn" data-wow-duration="2000ms">
                  <i class="icon-marketing"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newsletter;
