import React from "react";

const VLSIDesignverification = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>VLSI Design verification</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>VLSI Design verification</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      {/* <!-- start blog-page-area --> */}
      <section class="blog-page-area ptb-120">
        <div class="container">
          <div class="row">
            <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
              <div class="blog-page-left">
                <div class="blog-item">
                  <div class="blog-img middle-shade">
                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                    <img
                      src="assets/images/courses/VLSI-Design-Verification.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                  </div>
                  <div class="blog-content">
                    <h2>
                      <a href="blog-single.html">Course Content</a>
                    </h2>
                    <p>
                      Basics of RC circuits; Charging and discharging; governing
                      equations and their explanation; rise time and fall time,
                      RC time constant, Elmore delay model in distributed RC
                      network; concept of drive strength, contention, tri-state
                      and noise margin. Practice problems.
                    </p>
                    <h5>Binary Number systems</h5>
                    <p>
                      {" "}
                      Sign-magnitude (SM), 1’s complement, 2’s complement(TC);
                      addition of 2’complement numbers, Logical and arithmetic
                      shifting, sign extension, binary fractions; Practice
                      problems in logic circuits related to binary number
                      systems.
                    </p>
                    <p>
                      Combinational circuits: Karnaugh map, De-Morgan’s
                      theorems, Multiplexer as a universal element,
                      de-multiplexers, using multiplexers for constructing other
                      logic gates – N/AND, N/OR, XOR, XNOR, NOT, Buffer.
                      Practice problems in combinational logic
                    </p>
                    <p>
                      Working of NMOS FET from first principles. Concept of
                      threshold voltage; cutoff region, linear regin and
                      saturation region. Transmission of an imperfect 1/0 and
                      perfect 1/0; Building logic gates using MOS transistors as
                      transmission switches. Construction of 2-i/p NAND, NOR,
                      AND, OR gates, and inverter. Voltage transfer
                      characteristic (VTC) of an inverter.
                    </p>
                    <p>
                      More combinational circuits: Arithmetic comparator for 4
                      bits, cascaded arithmetic comparators, demultiplexers,
                      decoders, priority encoders, extended Priority encoders,
                      2-input hardware sorter, 4-input sorter, seven segment
                      decoder implementation, carry lookahead adder, carry save
                      adder, multiplier, Booth’s multiplier; Practice problems
                      in Combinational logic.
                    </p>
                    <h5>Sequential Logic</h5>
                    <p>
                      {" "}
                      Basic difference between sequential and combinational.
                      Construction of a 1-bit latch using transmission and
                      feedback CMOS switches. Construction of an edge triggered
                      flipflop using latches. Difference between the operation
                      of a latch and the operation of a flip flop. Introduction
                      to FSM. Timing parameters in sequential elements – setup
                      time, hold time and clk-to-Q propagation delay. Clock
                      skew. Practice problems
                    </p>
                    <p>
                      Different types of flipflops. D, JK and T flip flop;
                      Characteristic table and excitation tables, converting
                      from one type of FF to another; Timing parameters of flip
                      flops and Concept of metastability; Timing violations;
                      Clock Domain Crossing (CDC); single-bit 2-FF synchronizer.
                    </p>
                    <p>
                      General representation of a Finite State Machine (FSM);
                      Moore and Mealy State machines; Shift registers, Counters;
                      counters used as frequency dividers, Sequence detector,
                      design of a divide-by-5 counter with 50% duty cycle.
                    </p>
                    <h5>Problem solving in FSM</h5>
                    <p>
                      {" "}
                      Washing machine controller, Candy vending machine
                      controller, Traffic signal controller, Round robin
                      arbiter, etc. Problems in frequency division using
                      counters and how to get an output frequency with a certain
                      division ratio and duty cycle.
                    </p>
                    <h5>Introduction to STA</h5>
                    <p>
                      Different types of timing paths; False path and multicycle
                      path. Clock skew; max permissible clock skey in a circuit
                      and how to overcome hold timing violation and setup timing
                      violations in circuits; Useful skew and max frequency of
                      operation.
                    </p>
                    <h5>Verilog HDL</h5>
                    <p>
                      {" "}
                      Introduction, Data Types, Verilog operators, different
                      levels of modelling, behavioral modeling, always vs
                      initial blocks, blocking vs nonblocking assignments,
                      Stratified event queue, tasks and functions, recursive
                      tasks and functions, compiler directives, system
                      functions, Writing the design code for the problems done;
                      writing test benches in Verilog.
                    </p>
                    <h5>System Verilog</h5>
                    <p>
                      {" "}
                      New data types, processes, fundamentals of OOP,
                      transaction class, generator class, interface and
                      modports, driver class, environment class, scoreboard
                      class etc. Porting the Washing machine controller test
                      bench and candy vending machine controller testbench to
                      SV.
                    </p>
                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
              <div class="blog-sidebar">
                <div class="category-widget widget">
                  <h3>Category</h3>
                  <ul>
                    <li>
                      <a href="/VLSI-physical-design">
                        <span>
                          <i class="icon-arrow"></i>VLSI PHYSICAL DESIGN{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-analog-layout">
                        <span>
                          <i class="icon-arrow"></i>VLSI ANALOG LAYOUT{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-desgin-verification">
                        <span>
                          <i class="icon-arrow"></i>VLSI Design Verification{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-Embeded-systems">
                        <span>
                          <i class="icon-arrow"></i>VLSI Embedded Systems{" "}
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                     Exam Syllabus
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Need to qualify for the screening test and technical interview. The test would be conducted in Basic Electronics – BJT, FET, CMOS; Digital Electronics – Number Systems, Boolean Algebra, K-Maps, Logic Gates, Logic Families, Combinational Circuits, Sequential Circuits, and Counters.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                   Qualification
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    B.E/B.Tech in ECE/EEE, M.E/M.Tech/M.S in VLSI/Embedded Systems/Digital Electronics.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                     Academics
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Aggregate of 65% & Above
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                    No. of Seats
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    80
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
               Fee
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    ₹95,000/- [+ 18% GST].
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end blog-section --> */}{" "}
    </div>
  );
};

export default VLSIDesignverification;
