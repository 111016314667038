import React from "react";

const VLSIAnaloglayout = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>VLSI Analog Layout</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>VLSI Analog Layout</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      {/* <!-- start blog-page-area --> */}
      <section class="blog-page-area ptb-120">
        <div class="container">
          <div class="row">
            <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
              <div class="blog-page-left">
                <div class="blog-item">
                  <div class="blog-img middle-shade">
                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                    <img
                      src="assets/images/courses/vlsi-analog-layout.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                  </div>
                  <div class="blog-content">
                    <h2>
                      <a href="blog-single.html">Course Content</a>
                    </h2>
                    <p>
                      Fundamental concepts in MOSFET, Second order effects,
                      Digital logic gates design, Fabrication concepts,
                      Latch-Up, Antenna effect, and Standard cell layout.
                      Fundamentals of analog circuits like Amplifiers, Opamps,
                      Differential amplifiers, BGR, DAC, and PLL. Analog layout
                      concepts like Module based floor plan techniques, Device
                      Matching techniques, Routing techniques (Power, Signal),
                      Shielding concepts, Deep sub-micron process challenges
                      like Well proximity, LOD, and STI effects, ESD concepts,
                      and Layout guidelines. Physical verification concepts like
                      LVS, DRC, and Antenna with Parasitic extraction. Exposure
                      to the Importance of reliability checks like EMIR
                      analysis, DFM checks, ESD path checks, Chip level issues,
                      and FINFET fundamentals. The trainees get to work on 4 to
                      5 different designs. The assignments are designed in such
                      a way that our trainees have a clear understanding of
                      developing layouts from schematics following the design
                      constraints, process challenges, and layout guidelines and
                      verified their designs and extracted within the given
                      specification limits.
                    </p>
                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
              <div class="blog-sidebar">
                <div class="category-widget widget">
                  <h3>Category</h3>
                  <ul>
                    <li>
                      <a href="/VLSI-physical-design">
                        <span>
                          <i class="icon-arrow"></i>VLSI PHYSICAL DESIGN{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-analog-layout">
                        <span>
                          <i class="icon-arrow"></i>VLSI ANALOG LAYOUT{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-desgin-verification">
                        <span>
                          <i class="icon-arrow"></i>VLSI Design Verification{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-Embeded-systems">
                        <span>
                          <i class="icon-arrow"></i>VLSI Embedded Systems{" "}
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
             
              <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                     Exam Syllabus
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Need to qualify for the screening test and technical interview. The test would be conducted in Basic Electronics – R, L, C, Diode, BJT, FET, CMOS, etc. Analog basic circuits Like RLC circuits, Amplifiers, Opamp, Oscillators, and Network Theory. Digital Electronics – Number Systems, Boolean Algebra, K-Maps, Logic Gates, Logic Families, Combinational Circuits, Sequential Circuits, and Counters.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                   Qualification
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    B.E/B.Tech in ECE/EEE, M.E/M.Tech/M.S in VLSI/Embedded Systems/Digital Electronics.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                     Academics
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Aggregate of 65% & Above
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                    No. of Seats
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    80
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
               Fee
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    ₹80,000/- [+ 18% GST].
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end blog-section --> */}
    </div>
  );
};

export default VLSIAnaloglayout;
