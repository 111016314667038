import React from "react";
import "./ourservices.css";
import { Link } from "react-router-dom";

const OurService = () => {
  return (
    <div>
      <div class="container">
        <div class="container">
          <div className="ch-top-title-s3" style={{ marginTop: "50px" }}>
            <h2 style={{ fontWeight: "300" }}>
              Route 2 SOC Design and IP Services
            </h2>
          </div>
          <div class="row">
            <div class="col">
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src="assets/images/service/RTL-Design.png"
                      class="img-fluid rounded-start"
                      style={{ height: "100%" }}
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">RTL Design</h5>
                      <p class="card-text">
                        Our top-tier Front-End Engineers adeptly collaborate
                        with architectural teams to seamlessly transition
                        projects from Specification to Netlist.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <img
                  src="assets/images/service/Design-verfication.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">Design Verification</h5>
                  <p class="card-text">
                    Our seasoned team masters top EDA tools for flawless design
                    verification, serving tier-1 clients with precision and
                    expertise.
                  </p>
                  {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src="assets/images/service/dft.png"
                      class="img-fluid rounded-start"
                      style={{ height: "100%" }}
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">DFT</h5>
                      <p class="card-text">
                        Our ROUTE2SOC DFT team ensures both accelerated
                        time-to-market and enhanced execution quality,
                        illustrating the transformative impact of superior
                        Design For Test (DFT) services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="card mb-3">
                <img
                  src="assets/images/service/Physical-design.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">Physical Design</h5>
                  <p class="card-text">
                    ROUTE2SOC Tecnoloies Physical Design teams use their proven
                    flows and processes to build the entire Back End flow and
                    take full responsibility from RTL/Netlist to GDSII.
                  </p>
                  {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-4">
                    <img
                      src="assets/images/service/Embedded-Engineering.png"
                      class="img-fluid rounded-start"
                      style={{ height: "100%" }}
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Embedded Engineering</h5>
                      <p class="card-text">
                        Embedded systems now prioritize real-time capabilities,
                        emphasizing accuracy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <img
                  src="assets/images/service/Analog-Circuit-Design.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">ANALOG DESIGN</h5>
                  <p class="card-text">
                    Skilled in analog component layout and crafting standard
                    cell libraries for optimal area, power, and timing.
                  </p>
                  {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Automotive</h5>
                      <p class="card-text">
                        The automotive market embraces real-time integration of
                        Computer Vision, Machine Learning, and Deep Learning,
                        prioritizing accuracy.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      src="assets/images/service/automative.png"
                      class="img-fluid rounded-end"
                      style={{ height: "100%" }}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <img
                  src="assets/images/service/system-design.png"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <h5 class="card-title">System Design</h5>
                  <p class="card-text">
                    Leveraging on our maturity and strong industry reputation
                    for SoC/hardware design and embedded SW/FW across varied
                    wireless and networking technologies.
                  </p>
                  {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">Artificial Intelligence</h5>
                      <p class="card-text">
                        We excel in developing specialized Machine learning and
                        Deep Learning solutions across diverse industries.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      src="assets/images/service/ai.png"
                      class="img-fluid rounded-start"
                      style={{ height: "100%" }}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section
        className="service-area-2 ptb-114"
        style={{ padding: "114px 0" }}
      >
        <div className="container">
          <div className="service-wrap">
            <div className="ch-top-title-s3">
              <h2 style={{ fontWeight: "300" }}>Route 2 SOC Design and IP Services</h2>
            </div>
            <div class="row">
            <div class="container">
  <h1>CSS buttons example</h1>
  <div class="button button-1">Neon</div>
  </div>
              
            </div>
          </div>
        </div>
      </section> */}

      <div className="service-single-page ptb-114" style={{backgroundImage:"url('assets/images/hero/bg-shape-1.jpg')"}}>
        <div className="container-fluid">
          <div className="ch-top-title-s3"><br></br><br></br>
            <h2 style={{ fontWeight: "300" }}>
              Getting The Students Industry Ready
            </h2>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="col bg-info"
                style={{
                  // backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Selective Training</h3>
                <p style={{ textAlign: "center" }}>
                  Students with multiple persona are trained according to their
                  strengths & weaknesses.
                </p>
              </div>
              <div
                class="col"
                style={{
                  backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Expert Instructors</h3>
                <p style={{ textAlign: "center" }}>
                  Radiating the ultimate domain expertise from the Industry
                  Experts.
                </p>
              </div>
              <div
                class="col bg-warning"
                style={{
                  // backgroundColor: "lightblue",
                  padding: "10px",
                  borderRadius: "10px",
                  margin: "5px",
                }}
              >
                <h3 style={{ textAlign: "center" }}>Lifetime Learning</h3>
                <p style={{ textAlign: "center" }}>
                  Job-oriented Learning that sets the career for lifetime.
                </p>
              </div>
            </div>
          </div>
          <br></br>
          <div className="service-single-top">
            <div className="row">
              <div className="col-lg-9">
                <div className="service-single-img mt-5">
                  {/* <img src="assets/images/service-single/single.jpg" alt="" /> */}

                  <h2 style={{ textAlign: "center",fontSize:"42px" }}> Our Courses</h2>
                  <br />
                  <p
                    style={{
                      width: "80%",
                      margin: "auto",
                      textAlign: "center",
                      color:"white"
                    }}
                  >
                    The state-of-the-art infrastructure of ROUTE2SOC can
                    accommodate ~ 1000 students per year (300 per batch at a
                    time) to train students in multiple domains like Physical
                    Design, Analog Layout, Design Verification & Embedded
                    Systems.
                  </p><br></br>
                  <div
                    class="card"
                    style={{
                      width: "17rem",
                      margin: "10px",
                      float: "left",
                    }}
                  >
                    <img
                      src="assets/images/courses/vlsi-physical-design.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI PHYSICAL DESIGN
                      </h3>
                      <p class="card-text">
                        Be that Physical Designer with the world-class
                        industry-oriented VLSI – Physical Design training
                        program using the Cadence Encounter tool.
                      </p>
                      <Link to="/VLSI-physical-design">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{ width: "16rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/vlsi-analog-layout.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI ANALOG LAYOUT
                      </h3>
                      <p class="card-text">
                        Be that Analog Layout Designer with the world-class
                        industry-oriented training program using the Cadence
                        Virtuoso tool.
                      </p>
                      <Link to="/VLSI-analog-layout">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card mb-5"
                    style={{ width: "16rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/VLSI-Design-Verification.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI Design Verification
                      </h3>
                      <p class="card-text">
                        Bridging innovation with precision through
                        industry-grade VLSI Design Verification training using
                        Cadence & Mentor Graphics tools.
                      </p>
                      <Link to="/VLSI-desgin-verification">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div
                    class="card"
                    style={{ width: "16rem", margin: "10px", float: "left" }}
                  >
                    <img
                      src="assets/images/courses/VLSI-Embedded-Systems.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    <div class="card-body">
                      <h3 class="card-title" style={{ textAlign: "center" }}>
                        VLSI Embedded Systems
                      </h3>
                      <p class="card-text">
                        Bring the change by joining the world-class
                        industry-oriented Embedded Systems training program
                        offered by ROUTE2SOC.
                      </p>
                      <Link to="/VLSI-Embeded-systems">
                        <div class="text-center">
                          <button
                            type="button"
                            class="btn"
                            style={{
                              backgroundColor: " #1B1A1A",
                              color: " white",
                            }}
                          >
                            View More
                          </button>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3" >
                <br></br>
                <div className="service-single-category">
                  <h3>Key aspects and stages of VLSI design:</h3>
                  <ul>
                    <li>
                      <a href="#">Specification</a>
                    </li>
                    <li>
                      <a href="#">Architectural Design</a>
                    </li>
                    <li>
                      <a href="#">Logic Design</a>
                    </li>
                    <li>
                      <a href="#">Circuit Design</a>
                    </li>
                    <li>
                      <a href="#">Physical Design</a>
                    </li>
                    <li>
                      <a href="#">Verification</a>
                    </li>
                    {/* <li>
                      <a href="#">Manufacturingn</a>
                    </li> */}
                    <li>
                      <a href="#">Testing and Debugging</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
