import React from "react";

const VLSIEmbededsystems = () => {
  return (
    <div>
      {/* <!-- start page-title --> */}
      <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>VLSI Embedded Systems</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>VLSI Embedded Systems</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
      {/* <!-- start blog-page-area --> */}
      <section class="blog-page-area ptb-120">
        <div class="container">
          <div class="row">
            <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
              <div class="blog-page-left">
                <div class="blog-item">
                  <div class="blog-img middle-shade">
                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                    <img
                      src="assets/images/courses/VLSI-Embedded-Systems.jpg"
                      class="card-img-top"
                      alt="..."
                    />
                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                  </div>
                  <div class="blog-content">
                    <h2>
                      <a href="">Course Content</a>
                    </h2>
                    <p>
                      Introduction to C, Elements of C, Operators, Flow Control
                      Statements, Functions, Arrays, Pointers, Macros, Dynamic
                      Memory Allocation, Strings, Structures, Unions, File
                      handling, Enumeration (or enum), Renaming Data types with
                      typedef, Bit-Manipulation, Type Casting, Executable file
                      format, command-line arguments, qualifiers, Compilation
                      stages, Debugging Techniques, Data Structure Basics,
                      Arrays, Single & double linked list, Stack and Queue,
                      Sorting Techniques, Searching Techniques, construction of
                      libraries.
                    </p>
                    <h5>OS concepts and socket programming</h5>
                    <p>
                      Kernel Architecture, CPU Scheduling, Deadlock &
                      starvation, Linux Interfaces, APIs and System Calls(Shell&
                      Services, Utilities, Applications, Libraries), Memory
                      Management, File Sub-System, Process Control, signals,
                      threads, IPC Mechanisms (POSIX & SYSTEM-V), High
                      Performance, Secure coding through OS features, Network,
                      Internet protocols(TCP/UDP), Client-Server Programming,
                      Multi-threaded Programming, Lab Practice, and assignments.
                    </p>
                    <h5>Kernel and device drivers</h5>

                    <p>
                      Introduction to Embedded Linux & its basics, Embedded
                      Linux Build System Tools, Make files and kconfig files,
                      Toolchains, Kernel configuration, and compilation, Yocto
                      project, Build root, Git version control system, Device
                      Drivers(character, block & network), Device model in
                      Linux, Kernel API’s, Virtual file systems /proc and
                      /sysfs, kobject, ksets, mmap, ioremap, kmap,
                      platform_device, platform_driver, Device Tree, Interrupts,
                      Concurrency and Race condition handling.
                    </p>
                    <h5>Communication Protocols</h5>
                    <p>UART, I2C, SPI, USB,PCI.</p>
                    <h5>Architectures</h5>
                    <p>X-86, ARM.</p>
                    <h5>Tools</h5>
                    <p>
                      VI editor, CC Compiler, GDB (Debugger), Executable file
                      format, Obj-dump,read-elf, Make files & Make Utilities,
                      strace, ltrace, mtrace, dtrace, mtrace & Valgrind.
                    </p>
                    <h5>Projects</h5>
                    <p>
                      Device drivers with practical examples on Host and
                      Development Boards. <br /> <br />
                      (C/Device Driver project using Raspberry PI/Beagle-bone
                      Black boards). <br /> <br />
                      Practical knowledge & experience in various Industrial
                      Instruments and Equipment.
                    </p>
                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
              <div class="blog-sidebar">
                <div class="category-widget widget">
                  <h3>Category</h3>
                  <ul>
                    <li>
                      <a href="/VLSI-physical-design">
                        <span>
                          <i class="icon-arrow"></i>VLSI PHYSICAL DESIGN{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-analog-layout">
                        <span>
                          <i class="icon-arrow"></i>VLSI ANALOG LAYOUT{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-desgin-verification">
                        <span>
                          <i class="icon-arrow"></i>VLSI Design Verification{" "}
                        </span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/VLSI-Embeded-systems">
                        <span>
                          <i class="icon-arrow"></i>VLSI Embedded Systems{" "}
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion mt-5" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                     Exam Syllabus
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    The test would be conducted in Basics of C programming, Microprocessor & Micro-Controller, Electronics – BJT, FET, CMOS; Digital Electronics – Logic Gates, Logic Families, Logical Reasoning / Aptitude.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                   Qualification
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    B.E/B.Tech in ECE/EEE, M.E/M.Tech/M.S in VLSI/Embedded Systems/Digital Electronics.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                     Academics
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    Aggregate of 65% & Above
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                    No. of Seats
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    50
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
               Fee
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                    ₹85,000/- [+ 18% GST].
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end blog-section --> */}
    </div>
  );
};

export default VLSIEmbededsystems;
