import React from "react";

const Chooseus = () => {
  return (
    <div>
      <section
        className="choose-area ptb-120"
        style={{
          background: "url(assets/images/choose.png) no-repeat center top",
          backgroundSize: "cover",
        }}
      >
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="choose-wrap">
                <div className="ch-top-title-s2">
                  <h2 style={{fontWeight:"300"}}>Why Choose ROUTE2SOC ?</h2>
                </div>
                <p>
                To explore new frontiers in semiconductor design and to be the partner of choice for our clients in the world a better place.{" "}
                </p>

                <div className="choose-items">
                  <div className="info info-save-world">
                    <div className="info-icon">
                    <i class="fa fa-microchip" aria-hidden="true"></i>
                    </div>
                    <div className="info-text">
                      <h3>Commitment</h3>
                     
                    </div>
                  </div>
                  <div className="info info-renewable">
                    <div className="info-icon">
                    <i class="fa fa-microchip" aria-hidden="true"></i>
                    </div>
                    <div className="info-text">
                      <h3>Strong work ethics</h3>
                     
                    </div>
                  </div>
                  <div className="info info-energy-plan">
                    <div className="info-icon">
                      <i class="fa fa-microchip" aria-hidden="true"></i>
                    </div>
                    <div className="info-text">
                      <h3>Team work</h3>
                     
                    </div>
                  </div>
                  <div className="info info-install">
                    <div className="info-icon">
                    <i class="fa fa-microchip" aria-hidden="true"></i>
                    </div>
                    <div className="info-text">
                      <h3>A Trusted partner </h3>
                    </div>
                  </div>
                </div>

                <div className="choose-features">
                  {/* <div className="choose-icon">
                    <i>
                      <img src="assets/images/anergey.png" alt="" />
                    </i>
                  </div> */}
                  <div className="choose-list">
                    <ul>
                      <li>
                        <i className="ti-check-box"></i>Flexible 40 h/week adjusted to your schedule

                      </li>
                      <li>
                        <i className="ti-check-box"></i>Save up to 45% with reduced costs and no overhead or hidden fees
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Access control for Cleanroom entry and virtual access restrictions
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Customized project status reports
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Efficient model for collaborative work
                      </li>
                    </ul>
                  </div>
                  <div className="choose-list">
                    <ul>
                      <li>
                        <i className="ti-check-box"></i>Work with experienced specialists
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Teams working across all time zones
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Security and confidentiality
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Flexible system of project management
                      </li>
                      <li>
                        <i className="ti-check-box"></i>Easy control and clear communication in English
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solar-panel">
          <img src="assets/images/solar-panel.png" alt="" style={{width:"48%", float:"right"}} />
          {/* <div className="sun-shape">
            <img src="assets/images/sun-shape.png" alt="" />
          </div> */}
        </div>
        <div className="line-vector">
          <img src="assets/images/line-vector.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Chooseus;
