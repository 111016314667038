import React from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { BrowserRouter  , Route, Routes } from 'react-router-dom';
import Blog from './Components/Blog';
import About from './Components/Pages/About';
import Services from './Components/Pages/Services';
import Products from './Components/Pages/Products';
import Gallery from './Components/Pages/Gallery';
import Contacts from './Components/Pages/Contacts';
import Semiconservices from './Components/Pages/Semiconservices';
import Embededservices from './Components/Pages/Embededservices';
import Training from './Components/Pages/Training';
import VLSIPhysicaldesign from './Components/Pages/Courses/VLSIPhysicaldesign';
import VLSIAnaloglayout from './Components/Pages/Courses/VLSIAnaloglayout';
import VLSIDesignverification from './Components/Pages/Courses/VLSIDesignverification';
import VLSIEmbededsystems from './Components/Pages/Courses/VLSIEmbededsystems';
import Designfortestability from './Components/Pages/Semiconservices/Designfortestability';
import ASICRTLdesign from './Components/Pages/Semiconservices/ASICRTLdesign';
import Physicaldesign from './Components/Pages/Semiconservices/Physicaldesign';
import FPGAdesign from './Components/Pages/Semiconservices/FPGAdesign';
import Multimedia from './Components/Pages/Embeddedservices/Multimedia';
import Automotive from './Components/Pages/Embeddedservices/Automotive';
import Modem from './Components/Pages/Embeddedservices/Modem';
import Internetofthings from './Components/Pages/Embeddedservices/Internetofthings';

const App = () => {
  return (
    <>
     <BrowserRouter >
      <Header/> 
      <Routes>
        <Route path='/' element={<Blog/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/products' element={<Products/>}/>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/contacts' element={<Contacts/>}/>
        <Route path='/training' element={<Training/>}/>
        <Route path='/semiconservices' element={<Semiconservices/>}/>
        <Route path='/embededservices' element={<Embededservices/>}/>
        <Route path='/VLSI-physical-design' element={<VLSIPhysicaldesign/>}/>
        <Route path='/VLSI-analog-layout' element={<VLSIAnaloglayout/>}/>
        <Route path='/VLSI-desgin-verification' element={<VLSIDesignverification/>}/>
        <Route path='/VLSI-Embeded-systems' element={<VLSIEmbededsystems/>}/>
        <Route path='/design-for-testability' element={<Designfortestability/>}/>
        <Route path='/ASIC-RTL-design' element={<ASICRTLdesign/>}/>
        <Route path='/PhysicalDesign-STA' element={<Physicaldesign/>}/>
        <Route path='/FPGA-design' element={<FPGAdesign/>}/>
        <Route path='/multimedia' element={<Multimedia/>}/>
        <Route path='/automotive' element={<Automotive/>}/>
        <Route path='/modem' element={<Modem/>}/>
        <Route path='/internet-of-things' element={<Internetofthings/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
