import React from 'react';

const Designfortestability = () => {
  return (
    <div>
 {/* <!-- start page-title --> */}
 <section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Design for Testability</h2>
                  <ol>
                    <li>
                      <a href="">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Design for Testability</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img
                      src="assets/images/service/design-testability.jpg"
                      alt=""
                    />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="">Design for Testability</a>
                                    </h2>
                                    <p>ROUTE2SOC is a trusted leader in delivering comprehensive design for testability (DFT) solutions for complex semiconductor designs. Our experienced team excels in providing high-quality DFT solutions across diverse industry verticals.</p>
                                    <p>With a deep understanding of DFT methodologies and cutting-edge tools, we develop optimized DFT architectures to achieve maximum test coverage and reduced test time. Our expertise encompasses all aspects of DFT, including physical implementation, timing closure, physical verification, library generation, and characterization support.</p>
                                    <p>We leverage the latest technologies and tools, such as FinFET libraries, low power concepts, custom clock tree design, and CLP/MVRC checks. Our successful track record includes delivering DFT solutions for demanding designs like mobile GPU high-speed cores, CPU/GPU core/unit/block level, IoT/networking/connectivity programs, and library development.</p>
                                    <p>At ROUTE2SOC, we prioritize providing our clients with the highest quality DFT solutions tailored to their specific needs, while adhering to industry standards. Our commitment to continuous learning and improvement keeps us at the forefront of DFT technology and methodologies, enabling us to deliver innovative and effective solutions.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/design-for-testability"><span><i class="icon-arrow"></i>Design for Testability </span> </a></li>
                                    <li><a href="/ASIC-RTL-design"><span><i class="icon-arrow"></i>ASIC/ RTL Design</span></a></li>
                                    <li><a href="/PhysicalDesign-STA"><span><i class="icon-arrow"></i>Physical Design & STA </span> </a></li>
                                    <li><a href="/FPGA-design"><span><i class="icon-arrow"></i>FPGA Design </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   
                   
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default Designfortestability;
