import React from "react";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div>
      <section className="about-area ptb-120" style={{backgroundImage:"url('assets/images/hero/bg.png')"}}>
        <div className="container">
          <div className="about-wrap">
            <div className="row align-items-center">
              <div className="col col-lg-6 col-md-12 col-12">
                <div className="about-left-item fadeInLeftSlow">
                  <div className="about-left-wrap" style={{marginLeft:"50px"}}>
                    <div className="about-middle">
                      <div className="about-middle-text">
                        <img src="assets/images/hero/chip.png"/>
                      </div>
                    </div>
                    <div className="bottom-shape">
                      <img src="assets/images/about/shape-2.png" alt="" />
                    </div>
                    <div className="round-image-wrap">
                      <div className="image-1">
                        <img src="assets/images/about/about-1.jpg" alt="" />
                      </div>
                      <div className="image-2">
                        <img src="assets/images/about/about-2.jpg" alt="" />
                      </div>
                      <div className="image-3">
                        <img src="assets/images/about/about-3.jpg" alt="" />
                      </div>
                      <div className="image-4">
                        <img src="assets/images/about/about-4.jpg" alt="" />
                      </div>
                      <div className="image-5">
                        <img src="assets/images/about/about-5.jpg" alt="" />
                      </div>
                      <div className="image-6">
                        <img src="assets/images/about/about-6.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-lg-6 col-md-12 col-12">
                <div className="about-right-item fadeInRightSlow">
                  <div className="about-right-text">
                    <h2 style={{fontWeight:"300"}}>We Provide VLSI Services  </h2>
                    <h3 >Trusted Partner in Semiconductor Engineering</h3>
                    <p>
                    Route 2 SOC Technologies is an advanced technology services provider offering comprehensive solutions 
                    from concept to prototype, in the semiconductor space. We offer an integrated 
                    portfolio of services to our clients in key domains including digital, analog, 
                    high speed physical interface IP, Embedded Memory Compiler and EDA modelling.
                    </p>
                    <h3>The Route 2 SOC Advantage</h3>
                    <p>R2S is a trusted partner recognized by valued clients from various corners of the world.
                       We are the steadfast cornerstone in the journey of your product's lifecycle, engaging with 
                       you right from the conceptual stage and standing by your side throughout the arduous path to 
                       testing and production. Our reputation as a reliable and high-quality partner precedes us, 
                       ensuring you have comprehensive support every step of the way. When you choose R2S, 
                      you choose excellence, innovation, and unwavering commitment to your success.</p>
                    <Link to='/about'>
                    <div className="about-bottom">
                      <div className="about-bottom-btn">
                        <a href="#" className="ch-btn-style-1">
                          More About Us
                        </a>
                      </div>
                     
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dots-shape-1">
          <img src="assets/images/about/shape-1.png" alt="" />
        </div>
        <div className="dots-shape-2">
          <img src="assets/images/about/shape-1.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Aboutus;
