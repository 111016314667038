import React from 'react';

const Internetofthings = () => {
  return (
    <div>
{/* <!-- start page-title --> */}
<section class="page-title">
        <div class="container-fluid">
          <div class="page-title-wrap">
            <div class="row align-items-center">
              <div class="col col-md-6 col-12">
                <div class="breadcumb-wrap">
                  <h2>Internet of Things</h2>
                  <ol>
                    <li>
                      <a href="/">
                        <i class="icon-36"></i> Home
                      </a>
                    </li>
                    <li>Internet of Things</li>
                  </ol>
                </div>
              </div>
              {/* <div class="col col-lg-6">
                <div class="breadcumb-img">
                  <img src="assets/images/page-title-img.jpg" alt="" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end page-title --> */}
       {/* <!-- start blog-page-area --> */}
        <section class="blog-page-area ptb-120">
            <div class="container">
                <div class="row">
                    <div class="col col-lg-8 col-md-12 col-12 order-lg-2 order-1">
                        <div class="blog-page-left">
                            <div class="blog-item">
                                <div class="blog-img middle-shade">
                                    {/* <img src="assets/images/blog/blog-7.jpg" alt=""/> */}
                                    <img src="assets/images/service/vlsi-IOT.jpg" alt="" />
                                    {/* <ul>
                                        <li><i class="ti-tag"></i> Power</li>
                                        <li><i class="ti-comments"></i> Comments (05)</li>
                                    </ul> */}
                                </div>
                                <div class="blog-content">
                                    <h2><a href="">Internet of Things</a>
                                    </h2>
                                    <p>ROUTE2SOC offers comprehensive IoT solutions, leveraging cutting-edge technologies to drive digital transformation and enable connected experiences. Our services cover the entire IoT ecosystem, from device connectivity to data management and analytics.</p>
                                    <h5>Key Services:</h5>
                                    <ul>
                                      <li>Smart Sensor Integration: We specialize in integrating a wide range of sensors, enabling real-time data acquisition and environmental monitoring for IoT applications.</li><br></br>
                                      <li>Edge Computing: Our experts design and implement edge computing solutions, enabling real-time data processing, analytics, and decision-making at the edge of the network.</li><br></br>
                                      <li>IoT Data Analytics: We provide advanced data analytics solutions, leveraging machine learning and AI techniques to derive meaningful insights from IoT-generated data.</li><br></br>
                                      <li>Machine-to-Machine Communication: We develop secure and efficient communication protocols and frameworks for seamless interaction between IoT devices and systems.</li><br></br>
                                      <li>IoT Device Management: Our services include remote device management, firmware updates, security patching, and monitoring of IoT devices deployed in the field.</li><br></br>
                                      <li>Connectivity Solutions: We enable seamless connectivity through various protocols such as Wi-Fi, Bluetooth Low Energy (BLE), cellular (4G/5G), and LPWAN (LoRaWAN, NB-IoT) for diverse IoT use cases.</li><br></br>
                                      
                                    </ul>
                                    <h5>Tools and Technologies:</h5>
                                    <p>We utilize industry-leading tools and frameworks such as AWS IoT, Azure IoT, Google Cloud IoT, and MQTT for scalable and secure IoT deployments. Our expertise extends to programming languages like Python, Java, and C++ for IoT application development.</p>
                                    <p>ROUTE2SOC, in addition to our comprehensive IoT solutions, has developed a cutting-edge wearable device for monitoring key human health parameters. This wearable integrates advanced sensors and connectivity capabilities to provide real-time health data, enabling personalized health monitoring and improving overall well-being. With our expertise in IoT and healthcare, we are committed to driving innovation and leveraging technology to transform the healthcare industry and empower individuals to take control of their health.</p>
                                    <p>At ROUTE2SOC, we are dedicated to delivering robust and scalable IoT solutions, empowering organizations to harness the power of connected devices and unlock the full potential of the Internet of Things.</p>
                                    {/* <a class="ch-btn-style-3 ch-btn-animated" href="blog-single.html">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-12 order-lg-1 order-2">
                        <div class="blog-sidebar">
                            <div class="category-widget widget">
                                <h3>Category</h3>
                                <ul>
                                    <li><a href="/multimedia"><span><i class="icon-arrow"></i>Multimedia </span> </a></li>
                                    <li><a href="/automotive"><span><i class="icon-arrow"></i>Automotive</span></a></li>
                                    <li><a href="/modem"><span><i class="icon-arrow"></i>Modem </span> </a></li>
                                    <li><a href="/internet-of-things"><span><i class="icon-arrow"></i>Internet of Things </span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- end blog-section --> */}    </div>
  );
}

export default Internetofthings;
